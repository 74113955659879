import { useToggle } from 'react-use';
import Image, { StaticImageData } from 'next/image';
import IntroducingImg from './images/intro.png';
import BlueEggImg from './images/blue-egg.png';
import Button from '../../base/button.client';
import SubscriptionModal from '../learn/components/subscription-modal';
import CheckMark from './images/check-mark.png';

interface WhatSectionAProps {
    img?: StaticImageData;
}

export function WhatSectionA(props: WhatSectionAProps) {
    const [modalOpen, toggleModal] = useToggle(false);

    const onClickCloseModalHandler = (e) => {
        if (e.target === e.currentTarget) {
            toggleModal();
        }
    };

    const onClickCloseVectorHandler = (e) => {
        if (e.target) {
            toggleModal();
        }
    };
    return (
        <section id="intro" className="relative">
            <div className="container flex flex-col lg:flex-row justify-center relative">
                <div className="flex lg:basis-1/2 items-center order-2 lg:order-1 mt-10 lg:mt-0">
                    <div className="w-full md:w-[420px] relative">
                        <h2 className="text-body">
                            Unlock the Power of Snapi
                        </h2>
                        <div className='flex flex-col relative pt-5'>
                            <div className='bg-white border border-gray-light rounded-lg h-[54px] flex flex-row py-4 px-4 mb-2'>
                                <Image src={CheckMark}
                                    alt=""
                                    width={20}
                                    height={20}
                                    layout="fixed"
                                />
                                <p className='text-body flex flex-1 self-center text-left pl-3'>Easy-to-use test kit designed for children</p>
                            </div>
                            <div className='bg-white border border-gray-light rounded-lg h-[54px] flex flex-row py-4 px-4 mb-2'>
                                <Image src={CheckMark}
                                    alt=""
                                    width={20}
                                    height={20}
                                    layout="fixed"
                                />
                                <p className='text-body flex flex-1 self-center text-left pl-3'>Detailed Lab results</p>
                            </div>
                            <div className='bg-white border border-gray-light rounded-lg h-[54px] flex flex-row py-4 px-4 mb-2'>
                                <Image src={CheckMark}
                                    alt=""
                                    width={20}
                                    height={20}
                                    layout="fixed"
                                />
                                <p className='text-body flex flex-1 self-center text-left pl-3'>Evidence-based recommendations</p>
                            </div>
                            <div className='bg-white border border-gray-light rounded-lg h-[54px] flex flex-row py-4 px-4 mb-2'>
                                <Image src={CheckMark}
                                    alt=""
                                    width={20}
                                    height={20}
                                    layout="fixed"
                                />
                                <p className='text-body flex flex-1 self-center text-left pl-3'>1-1 dietitian support</p>
                            </div>
                        </div>
                        <span className="mt-8 flex justify-start">
                            <Button onClick={toggleModal}>get a sample report</Button>
                        </span>
                        <SubscriptionModal
                            onClose={onClickCloseVectorHandler}
                            onClick={onClickCloseModalHandler}
                            isOpen={modalOpen}
                        />
                    </div>
                </div>
                <div className="flex lg:basis-1/2 justify-center items-center order-1 lg:order-2">
                    <Image src={IntroducingImg} alt="" />
                </div>
            </div>
            {props.img && (
                <div className="absolute invisible md:visible bottom-[-20px] left-[30px] w-[130px] h-[76px] rotate-0 lg:w-[250px] lg:h-[148px] lg:bottom-[20px] lg:left-[30px] xl:w-[270px] xl:h-[178px]">
                    <Image src={props.img} alt="" />
                </div>
            )}
            <div className="absolute invisible md:visible left-[-50px] bottom-[-90px] w-[90px] lg:w-[120px] lg:left-[-70px] xl:w-[140px] xl:left-[-60px] xl:bottom-[-60px]">
                <Image src={BlueEggImg} alt="" />
            </div>
        </section>
    );
}
