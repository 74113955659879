import {
  faBacterium,
  faPersonBreastfeeding,
  faQuestion,
  faStomach,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import BacteriaFeetImg from './images/bacteria-feet.png';
import ChildImg from './images/child.png';

interface WhoSectionCardProps {
  title: string;
  children: React.ReactNode;
  icon: JSX.Element;
  className: string;
}

function WhoSectionCard({
  title,
  children,
  icon,
  className,
}: WhoSectionCardProps) {
  return (
    <div className={`p-4 rounded-xl lg:w-[348px] ${className}`}>
      {icon}
      <span className="flex mt-4 mb-2 text-body">
        <h4 className="">{title}</h4>
      </span>
      <p className="text-body">{children}</p>
    </div>
  );
}

export function WhoSection() {
  return (
    <section id="who">
      <div className="relative container z-20">
        <div className="relative mb-5 sm:mb-10">
          <h2 className="mb-3 sm:max-w-[340px] md:max-w-[454px] text-body">
            Snapi is for you if your baby is{' '}
            <span className="text-blue">0-5 years old*</span> and you:
          </h2>
          <p className="mb-10 max-w-[440px] text-body">
            * You can still do the Snapi test for your baby even if they are
            over 5 years old. The age of 0-5 is recommended, but not required.
          </p>
          <div className="absolute top-[-10px] right-0 invisible w-[0px] sm:visible sm:w-[132px]  md:top-[-30px] lg:w-[184px] lg:left-[734px] xl:w-[286px] xl:top-[-20px] xl:left-[900px]">
            <Image src={ChildImg} alt="" />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-y-7 sm:grid-cols-2 gap-7 lg:flex flex-wrap lg:gap-8">
          <WhoSectionCard
            title="Proactive Parenting"
            icon={
              <div className="h-[52px] w-[52px] rounded-[4px] p-5 bg-[#A3CEF3] text-[#71ADE0] flex items-center justify-center">
                <FontAwesomeIcon icon={faQuestion} className="h-8 w-8" />
              </div>
            }
            className="z-10 bg-blue-light"
          >
            You&apos;re looking for transparency into your baby&apos;s health,
            starting with the gut.
          </WhoSectionCard>
          <WhoSectionCard
            title="Eczema? Constipation? Allergies?"
            icon={
              <div className="h-[52px] w-[52px] rounded-[4px] p-5 bg-[#F2B3BC] text-[#DE8C9D] flex items-center justify-center">
                <FontAwesomeIcon icon={faStomach} className="h-8 w-8" />
              </div>
            }
            className="z-10 bg-pink-light"
          >
            You&apos;re ready to get to the root cause of your baby&apos;s gut
            health concerns.
          </WhoSectionCard>
          <WhoSectionCard
            title="C-section, Formula, Antibiotics"
            icon={
              <div className="h-[52px] w-[52px] rounded-[4px] p-5 bg-[#D3EDC8] text-[#56654F] flex items-center justify-center">
                <FontAwesomeIcon icon={faBacterium} className="h-8 w-8" />
              </div>
            }
            className=" lg:ml-[202px] xl:ml-[304px] z-10 bg-green-light"
          >
            Your baby&apos;s exposures to certain circumstances have an impact
            on the composition of their gut.
          </WhoSectionCard>
          <WhoSectionCard
            title="Breastfeeding"
            icon={
              <div className="h-[52px] w-[52px] rounded-[4px] p-5 bg-[#BFD4FD] text-[#86A6E6] flex items-center justify-center">
                <FontAwesomeIcon
                  icon={faPersonBreastfeeding}
                  className="h-8 w-8"
                />
              </div>
            }
            className="z-20 bg-purple-light"
          >
            Your diet is crucial to support your baby&apos;s gut health, as
            bacteria can be transferred through breast milk.
          </WhoSectionCard>
        </div>
        <div
          className="absolute invisible lg:visible
              lg:w-[170px] lg:h-[128px] lg:left-[70px] lg:bottom-[20px]
              xl:w-[215px] xl:h-[162px]"
        >
          <Image src={BacteriaFeetImg} alt="" />
        </div>
      </div>
    </section>
  );
}
