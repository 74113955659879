import { Faq } from '@snapi/types';
import { motion, Variants } from 'framer-motion';
import { NotionRenderer } from 'react-notion-x';

const topDash: Variants = {
  closed: {
    rotate: 90,
    transition: { type: 'spring', duration: 0.4, bounce: 0.2 },
  },
  opened: {
    rotate: 0,
    transition: { type: 'spring', duration: 0.4, bounce: 0.2 },
  },
};

type PlusToggleProps = {
  isOpened: boolean;
};

export const PlusToggle: React.FC<PlusToggleProps> = ({ isOpened }) => (
  <button
    role="switch"
    aria-label="Expand"
    aria-checked={isOpened}
    className="relative z-30 flex flex-col justify-center w-[10px] h-[10px]"
  >
    <motion.div
      variants={topDash}
      initial="closed"
      animate={isOpened ? 'opened' : 'closed'}
      className="absolute h-[2px] bg-body w-[10px]"
    />
    <span className="h-[2px] w-[10px] bg-body" />
  </button>
);

const CardVariants: Variants = {
  open: {
    height: 'auto',
    opacity: 1,
    zIndex: 1,
    transition: { duration: 0.4 },
  },
  closed: {
    height: 0,
    opacity: 0,
    zIndex: -1,
    transition: { duration: 0.4 },
    onTransitionEnd: () => ({
      zIndex: -1,
    }),
  },
};

type FaqCardProps = Omit<Faq, 'category'> & {
  selected: boolean;
  onSelect(): void;
};

export function FaqCard({
  id,
  question,
  answer,
  selected,
  onSelect,
}: FaqCardProps) {
  return (
    <div
      className="flex flex-col relative w-full bg-white rounded-[30px] mt-2 cursor-pointer shadow-[0px_4px_10px_0px_rgba(125, 125, 125, 0.15)]"
      onClick={onSelect}
    >
      <div className="flex flex-1 h-[60px] w-full justify-center text-[13px] sm:text-[16px] text-body font-semibold">
        <span className="flex flex-1 self-center text-left pl-5 pr-3">
          {question}
        </span>
        <span className="flex w-[60px] h-[60px] bg-green rounded-full items-center justify-center">
          <PlusToggle isOpened={selected} />
        </span>
      </div>
      <motion.div
        animate={selected ? 'open' : 'closed'}
        variants={CardVariants}
        initial="closed"
      >
        <div className="text-left pb-5 text-xs">
          <NotionRenderer blockId={id} recordMap={answer} />
        </div>
      </motion.div>
    </div>
  );
}
