import Image from 'next/image';
import RecomendationImg from './images/recommendations-image-1024.png';
import TrackImg from './images/monitor-image-1024.png';
import ReportImg from './images/report-image-1024.png';
import RecomendationImgMob from './images/recommendations-image-320.png';
import TrackImgMob from './images/monitor-image-320.png';
import ReportImgMob from './images/report-image-320.png';
import RecomendationImgTab from './images/recommendation-image.png';
import TrackImgTab from './images/monitor-image.png';
import ReportImgTab from './images/report-image.png';
import clsx from 'clsx';
import { ReactNode } from 'react';

interface HowUseCardProps {
  img: ReactNode;
  title: string;
  description: string;
  order?: string;
}

const howUseCard = ({ img, description, title, order }: HowUseCardProps) => (
  <div className="lg:flex justify-center items-center">
    <div
      className={clsx(
        order,
        'min-w-[290px] mb-5 lg:mb-0 basis-1/2 leading-[0px]'
      )}
    >
      {img}
    </div>
    <div className="basis-1/2 lg:pl-4 xl:pl-6">
      <h4 className="mb-2 xl:mb-3">{title}</h4>
      <p className="lg:w-[454px] xl:w-[484px]">{description}</p>
    </div>
  </div>
);

const AllHowUseCards: HowUseCardProps[] = [
  {
    img: (
      <div className="">
        <div className="hidden lg:flex">
          <Image src={RecomendationImg} alt="" />
        </div>
        <div className="sm:hidden">
          <Image src={RecomendationImgMob} alt="" />
        </div>
        <div className="hidden sm:flex lg:hidden">
          <Image src={RecomendationImgTab} alt="" />
        </div>
      </div>
    ),
    title: 'PERSONALIZED RECOMMENDATIONS & SUPPORT',
    description:
      'Snapi’s pediatricians, dietitians, and lactation specialist provide education and support from start to finish through personalized recommendations and 24/7 chat support. Snapi’s recommendations are loaded with interesting, easy-to-use recipes, as well as expert-approved probiotic supplements. ',
  },
  {
    img: (
      <div className="">
        <div className="hidden lg:flex">
          <Image src={ReportImg} alt="" />
        </div>
        <div className="sm:hidden">
          <Image src={ReportImgMob} alt="" />
        </div>
        <div className="hidden sm:flex lg:hidden">
          <Image src={ReportImgTab} alt="" />
        </div>
      </div>
    ),
    title: 'LAB ANALYSIS & INSIGHTS',
    description:
      'The results of your baby’s gut microbiome test include a detailed list of bacteria detected in your baby’s gut, in-depth descriptions of each bacteria, and associations related to common health conditions.',
    order: 'order-last',
  },
  {
    img: (
      <div className="">
        <div className="hidden lg:flex">
          <Image src={TrackImg} alt="" />
        </div>
        <div className="sm:hidden">
          <Image src={TrackImgMob} alt="" />
        </div>
        <div className="hidden sm:flex lg:hidden">
          <Image src={TrackImgTab} alt="" />
        </div>
      </div>
    ),
    title: 'MONITOR & TRACK',
    description:
      "The key to a healthy microbiome is monitoring. That’s why Snapi has developed new and innovative tools, such as easy-to-read graphs that guide you through the development of your baby's microbiome.",
  },
];

export function HowUseSection() {
  return (
    <section id="how-use" className="container text-body">
      <h2 className="mb-10">How will you use this information?</h2>
      <div className="flex flex-col gap-10 lg:gap-0">
        {AllHowUseCards.map((item, index) => {
          return <div key={index}>{howUseCard(item)}</div>;
        })}
      </div>
    </section>
  );
}
