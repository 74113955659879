import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import { ReactNode } from 'react';
import { useState } from 'react';
import { useToggle } from 'react-use';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as ArrowLeft } from './images/arrow-button-left.svg';
import { ReactComponent as ArrowRight } from './images/arrow-button-right.svg';
import { ReactComponent as ExcellentMark } from './images/excellent.svg';
import { ReactComponent as GoodMark } from './images/good.svg';
import { ReactComponent as FiveStars } from './images/5stars.svg';
import { ReactComponent as FourStars } from './images/4stars.svg';
import { Description } from '@snapi/luxury';
import { Subtitle } from '@snapi/luxury';

const CardVariants = {
  open: {
    height: 'auto',
    opacity: 1,
  },
  closed: {
    height: '132px',
    opacity: 1,
  },
};
const WrapperVariants = {
  open: {
    height: 'auto',
    opacity: 1,
  },
  closed: {
    height: '480px',
    opacity: 1,
  },
};

export const FiveStarsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => <FiveStars {...props} />;

export const FourStarsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => <FourStars {...props} />;

interface ReviewProps {
  index?: number;
  stars: ReactNode;
  title: string;
  review: string;
  button?: boolean;
  name: string;
  chartUse: ReactNode;
  chartReport: ReactNode;
}

const AllReviews: ReviewProps[] = [
  {
    index: 1,
    stars: <FiveStarsIcon />,
    title: 'Great product',
    review:
      'We really liked this service. I am even getting to do a free 15 minute consultation about the results in a few days! Can’t wait to talk and learn more about how to help my child.',
    name: 'Francesca H.',
    chartUse: (
      <div className="flex flex-col gap-2">
        <div className="font-semibold">
          <Description>Easy of Use</Description>
        </div>
        <ExcellentMark className="w-[258px] sm:w-[292px] lg:w-[258px] xl:w-[348px]" />
      </div>
    ),
    chartReport: (
      <div className="flex flex-col gap-2">
        <div className="font-semibold">
          {' '}
          <Description>Report Insight</Description>
        </div>
        <ExcellentMark className="w-[258px] sm:w-[292px] lg:w-[258px] xl:w-[348px]" />
      </div>
    ),
  },
  {
    index: 2,
    stars: <FiveStars />,
    title: 'Highly recommend everyone try this!',
    review:
      'The process was so quick and easy. We received our results so quickly, too! We love the way the report detailed the different types of bacteria, what their purpose was and how to increase/decrease certain bacteria.  The report was so easy to read. In addition, we had a virtual call with a Registered Dietician from Snapi, Dana, who explained the results in further detail.  She was so knowledgeable and explained everything to the dot! We can’t  wait to see the progress in the report in the next coming months. I highly recommend everyone try this as it is so quick, easy to read, and a necessity in furthering a healthy lifestyle for your children!',
    button: true,
    name: 'GP',
    chartUse: (
      <div className="flex flex-col gap-2">
        <div className="font-semibold">
          <Description>Easy of Use</Description>
        </div>
        <ExcellentMark className="w-[258px] sm:w-[292px] lg:w-[258px] xl:w-[348px]" />
      </div>
    ),
    chartReport: (
      <div className="flex flex-col gap-2">
        <div className="font-semibold">
          {' '}
          <Description>Report Insight</Description>
        </div>
        <ExcellentMark className="w-[258px] sm:w-[292px] lg:w-[258px] xl:w-[348px]" />
      </div>
    ),
  },
  {
    index: 3,
    stars: <FiveStarsIcon />,
    title: 'Awesome',
    review:
      'So easy to use and so helpful with results!!! Loved the information they gave back with the results.',
    name: 'Lauren E.',
    chartUse: (
      <div className="flex flex-col gap-2">
        <div className="font-semibold">
          <Description>Easy of Use</Description>
        </div>
        <ExcellentMark className="w-[258px] sm:w-[292px] lg:w-[258px] xl:w-[348px]" />
      </div>
    ),
    chartReport: (
      <div className="flex flex-col gap-2">
        <div className="font-semibold">
          {' '}
          <Description>Report Insight</Description>
        </div>
        <ExcellentMark className="w-[258px] sm:w-[292px] lg:w-[258px] xl:w-[348px]" />
      </div>
    ),
  },
  {
    index: 4,
    stars: <FourStarsIcon />,
    title: 'Happy with the results but not the site',
    review:
      'I’ve been having issues trying to log in to see my report. Sometimes I can get in, sometimes I can’t. The site is also not printer friendly so I had to screenshot everything to send my husband. Happy with the results and the recommendations. Hope to have improvements on the next result!',
    button: true,
    name: 'Jennifer K.',
    chartUse: (
      <div className="flex flex-col gap-2">
        <div className="font-semibold">
          <Description>Easy of Use</Description>
        </div>
        <ExcellentMark className="w-[258px] sm:w-[292px] lg:w-[258px] xl:w-[348px]" />
      </div>
    ),
    chartReport: (
      <div className="flex flex-col gap-2">
        <div className="font-semibold">
          {' '}
          <Description>Report Insight</Description>
        </div>
        <ExcellentMark className="w-[258px] sm:w-[292px] lg:w-[258px] xl:w-[348px]" />
      </div>
    ),
  },
  {
    index: 5,
    stars: <FiveStarsIcon />,
    title: 'Great info',
    review:
      'We loved all the information! It Ave great feedback on how to fix certain areas of our girls gut. As well as products that would best serve her.',
    name: 'Rachelle M.',

    chartUse: (
      <div className="flex flex-col gap-2">
        <div className="font-semibold">
          <Description>Easy of Use</Description>
        </div>
        <ExcellentMark className="w-[258px] sm:w-[292px] lg:w-[258px] xl:w-[348px]" />
      </div>
    ),
    chartReport: (
      <div className="flex flex-col gap-2">
        <div className="font-semibold">
          {' '}
          <Description>Report Insight</Description>
        </div>
        <ExcellentMark className="w-[258px] sm:w-[292px] lg:w-[258px] xl:w-[348px]" />
      </div>
    ),
  },
  {
    index: 6,
    stars: <FiveStarsIcon />,
    title: 'Gut health is so important!',
    review:
      'I love the ability to on my own test my babies gut health. The report was thorough and now I know exactly what to do to keep my little one in great health!',
    name: 'Brandie D.',
    chartUse: (
      <div className="flex flex-col gap-2">
        <div className="font-semibold">
          <Description>Easy of Use</Description>
        </div>
        <ExcellentMark className="w-[258px] sm:w-[292px] lg:w-[258px] xl:w-[348px]" />
      </div>
    ),
    chartReport: (
      <div className="flex flex-col gap-2">
        <div className="font-semibold">
          {' '}
          <Description>Report Insight</Description>
        </div>
        <ExcellentMark className="w-[258px] sm:w-[292px] lg:w-[258px] xl:w-[348px]" />
      </div>
    ),
  },
  {
    index: 7,
    stars: <FiveStars />,
    title: 'Microbiome test',
    review:
      'Easy to use! Results came back in a timely fashion, and the explanation/recommendations were easy to understand. Unfortunately our results also came back 38% "other" so there wasn"t any explanation for that. Other than that it was great!',
    button: true,
    name: 'Emily B.',
    chartUse: (
      <div className="flex flex-col gap-2">
        <div className="font-semibold">
          <Description>Easy of Use</Description>
        </div>
        <ExcellentMark className="w-[258px] sm:w-[292px] lg:w-[258px] xl:w-[348px]" />
      </div>
    ),
    chartReport: (
      <div className="flex flex-col gap-2">
        <div className="font-semibold">
          {' '}
          <Description>Report Insight</Description>
        </div>
        <ExcellentMark className="w-[258px] sm:w-[292px] lg:w-[258px] xl:w-[348px]" />
      </div>
    ),
  },
  {
    index: 8,
    stars: <FourStarsIcon />,
    title: 'Easy and fast',
    review:
      'The test kit was very easy to use and the result took about 2 weeks.',
    name: 'Tiffany J.',
    chartUse: (
      <div className="flex flex-col gap-2">
        <div className="font-semibold">
          <Description>Easy of Use</Description>
        </div>
        <ExcellentMark className="w-[258px] sm:w-[292px] lg:w-[258px] xl:w-[348px]" />
      </div>
    ),
    chartReport: (
      <div className="flex flex-col gap-2">
        <div className="font-semibold">
          {' '}
          <Description>Report Insight</Description>
        </div>
        <GoodMark className="w-[258px] sm:w-[292px] lg:w-[258px] xl:w-[348px]" />
      </div>
    ),
  },
];

const OneReview = ({
  index,
  stars,
  review,
  button,
  title,
  name,
  chartReport,
  chartUse,
}: ReviewProps) => {
  const [on, togle] = useToggle(false);

  return (
    <AnimatePresence>
      <div className="text-body flex flex-col">
        <motion.div
          initial={'closed'}
          animate={'open'}
          transition={{ duration: 0.7 }}
          style={{ overflow: 'hidden' }}
          variants={WrapperVariants}
          className="text-body flex flex-col justify-between "
        >
          <div className="min-h-[294px] m-4">
            <div className="mb-5">{stars}</div>
            <h4 className="mb-1">{title}</h4>

            {button ? (
              <motion.div
                initial={'closed'}
                animate={on ? 'open' : 'closed'}
                transition={{ duration: 0.6 }}
                style={{ overflow: 'hidden' }}
                variants={CardVariants}
              >
                {review}
              </motion.div>
            ) : (
              review
            )}

            <div className="text-body">
              {button && (
                <>
                  <button
                    onClick={togle}
                    className="text-body text-opacity-80  underline"
                  >
                    <Subtitle> {on ? 'Show less' : 'Read more'}</Subtitle>
                  </button>
                </>
              )}
              <h4 className="text-blue-semilight mt-2">{name}</h4>
            </div>
          </div>

          <div className="flex flex-col justify-center gap-5 text-purple-dark border-t-[1px] border-gray-soft pt-3 pb-5">
            <div className="mx-auto">{chartUse}</div>
            <div className="mx-auto">{chartReport}</div>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};
export function Reviews() {
  const [slideChange, setSlideChange] = useState(false);

  return (
    <section
      id="reviews"
      className="max-w-[1250px] xl:mx-auto pl-4 sm:pl-8 xl:pl-6"
    >
      <div className="flex justify-between sm:pr-8">
        <h2 className="text-body mb-10 max-w-[428px]">
          Testimonials that speak for themselves
        </h2>
        <div className=" gap-7 hidden sm:flex items-start mt-10">
          <span
            id="prev-button"
            className="flex items-center z-10 cursor-pointer"
          >
            <ArrowLeft className="flex w-10 h-10 bg-white rounded-full shadow-lg" />
          </span>
          <span
            id="next-button"
            className=" flex items-center z-10 cursor-pointer"
          >
            <ArrowRight className="flex w-10 h-10 bg-white rounded-full shadow-lg" />
          </span>
        </div>
      </div>
      <Swiper
        className="flex"
        slidesPerView="auto"
        spaceBetween={30}
        onSlideChange={() => {
          setSlideChange(true);
        }}
        pagination={{
          clickable: true,
          bulletActiveClass: 'bg-body opacity-100',
        }}
        navigation={{
          enabled: true,
          nextEl: '#next-button',
          prevEl: '#prev-button',
        }}
        modules={[Navigation, Pagination]}
      >
        <>
          {AllReviews.map((item) => {
            return (
              <SwiperSlide
                key={item.name}
                className="mb-12 flex justify-center w-[290px] sm:w-[324px] lg:w-[290px] xl:w-[380px]"
              >
                {(data) => {
                  return (
                    <div className="flex flex-col justify-start items-center bg-white rounded-[20px] ">
                      <OneReview {...item} />
                    </div>
                  );
                }}
              </SwiperSlide>
            );
          })}
        </>
      </Swiper>
    </section>
  );
}
