import Link from 'next/link';
import FaqImage from './images/faq-image.png';
import Image from 'next/image';
import { Faq } from '@snapi/types';
import { FaqCard } from './faq-card';

interface FaqPanelProps {
  slug: string;
  category: string;
  questions: Faq[];
  selected: string | null;
  onSelect(id: string): void;
}

export function FaqPanel({
  slug,
  category,
  questions,
  selected,
  onSelect,
}: FaqPanelProps) {
  return (
    <div id={slug} className="flex flex-col pb-8">
      <h3 className="mt-7 mb-3">{category}</h3>
      <div className="flex flex-1 flex-col space-y-2">
        {questions.map((faq) => (
          <FaqCard
            key={faq.id}
            {...faq}
            selected={selected === faq.id}
            onSelect={() => onSelect(faq.id)}
          />
        ))}
      </div>
      {category === 'Ordering a kit' &&  (<div className='bg-pink my-10 sm:h-[140px] rounded-[20px] px-4 py-4 flex flex-col-reverse sm:flex-row'>
            <div className=''>
              <h4 className='text-pink-dark'>Ready to buy our test?</h4>
              <p className='text-pink-dark max-w-[334px] pt-2'>Join now to save 25% and keep track of your baby's health on a regular basis.</p>
              <div className="mt-5">
                <Link href="https://www.snapihealth.com/#buy-variants">
                  <button className="w-full min-w-[270px] sm:w-[280px] h-[60px] rounded-[10px] uppercase shadow-[1px_2px_0_0_#7DA46C] bg-green text-body">
                  <h5>Choose the plan</h5>
                  </button>
                </Link>
              </div>
            </div>
            
            <div className='mx-auto justify-end self-center w-[211px] h-[155px] sm:w-[201px] sm:h-[172px]'>
              <Image src={FaqImage} alt="" />
            </div> 
          </div>)}
    </div>
  );
}
