import { ReactComponent as BigCircle } from './images/big-circle.svg';
import { ReactComponent as MediumBlobs } from './images/medium-blobs.svg';
import { ReactComponent as MediumCircle } from './images/medium-circle.svg';
import { ReactComponent as SmallCircle } from './images/small-circle.svg';
import { ReactComponent as SmallEgg } from './images/small-egg.svg';
import { ReactComponent as PhraseArrow } from './images/phrase-arrow.svg';
import { ClueCard } from '@snapi/luxury';

export function PhraseSection() {
  return (
    <section id="phrase" className="relative">
      <div className="flex flex-col items-center">
        <div className="flex items-center justify-center relative text-center mb-3 w-[290px] xs:w-[370px] sm:w-[564px] md:w-[656px] lg:w-[668px]">
          <BigCircle className="relative w-[290px] sm:w-[400px]" />
          <h2 className="absolute">
            Your baby&apos;s gut microbiome is the foundation of a healthy
            immune system. Optimize your baby&apos;s health for life.
          </h2>
          <div className="text-center sm:text-start w-[200px] sm:w-[226px] text-[24px] mb-[6px] absolute z-10 right-[48px] xs:right-[88px] bottom-[12px] sm:right-[-8px] sm:bottom-[100px] md:bottom-[92px] lg:bottom-[60px] xl:bottom-[112px] md:right-[56px] lg:right-[120px] xl:right-[-200px]">
            <ClueCard
              description="Early Development of the Gut Microbiota and Immune Health"
              href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4243441/"
              orientation="bottom"
            />
          </div>
          <MediumBlobs
            className="absolute invisible sm:visible w-[100px] 
                md:w-[128px] sm:top-[-52px] sm:left-[-56px] md:top-[-52px] 
                md:left-[-44px] lg:left-[-148px] xl:left-[-260px]"
          />

          <MediumCircle
            className="absolute invisible sm:visible w-[108px] md:w-[148px] sm:right-[-32px] 
                sm:bottom-[-20px] md:right-[-40px] md:bottom-[-20px] lg:right-[-140px] 
                lg:bottom-[-20px] xl:right-[-248px] xl:bottom-[-10px]"
          />

          <SmallCircle
            className="absolute top-[-40px] right-[20px] sm:top-[-10px] 
                sm:right-[120px] xl:right-[140px]"
          />

          <SmallEgg
            className="absolute left-[20px] 
                bottom-[-32px] sm:left-[60px] sm:bottom-[10px]"
          />

          <PhraseArrow
            className="absolute w-[40px] bottom-[-140px] sm:w-[70px] lg:w-[86px] 
                sm:left-[240px] sm:bottom-[-92px] md:bottom-[-100px] lg:bottom-[-148px] xl:bottom-[-120px] md:left-[280px]"
          />
        </div>
      </div>
    </section>
  );
}
