import Image from 'next/image';
import FaqCoverImg from './images/faq-cover.png';

export function FaqCover() {
  return (
    <div className="flex flex-col bg-[#90C5EC]">
      <div className="flex flex-col mx-auto">
        <h1 className="mx-auto mt-[114px] sm:mt-[214px] md:mt-[190px] text-white text-4xl sm:text-6xl">
          FAQ
        </h1>
      </div>
      <div className="h-[40px] lg:block bg-[#90C5EC]"></div>
      <div className="flex">
        <Image src={FaqCoverImg} alt="cover" className="w-full h-full" />
      </div>
    </div>
  );
}
