import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Sentry from '@sentry/react';
import SubscriptionNotification from './subscription-notification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import { CloseIcon } from '@snapi/icons';
import { ReactComponent as PinkCircle } from './images/pink-circle.svg';
import { ReactComponent as GreenCircle } from './images/green-circle.svg';
import { ReactComponent as WhiteCircle } from './images/white-circle.svg';
import { Input } from '@snapi/form';

const Spinner = () => (
  <div className="ml-2 z-70 inline">
    <FontAwesomeIcon
      icon={faCircleNotch}
      size="lg"
      className="fa-solid fa-circle-notch fa-spin"
    />
  </div>
);

const Overlay = {
  visible: { opacity: 0.75 },
  hidden: { opacity: 0 },
};

const ModalAnimation = {
  closed: { opacity: 0 },
  opened: { opacity: 1 },
};

interface SubscriptionModalProps {
  onClick: (e) => void;
  onClose: (e) => void;
  isOpen: boolean;
}

export default function SubscriptionModal({
  onClick,
  onClose,
  isOpen,
}: SubscriptionModalProps) {
  const { register, handleSubmit, reset } = useForm();
  const [showNotification, setShowNotification] = useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  async function onSubmit(data, e) {
    try {
      setShowSpinner(true);
      const { email, ageInMonths } = data;
      const response = await fetch(
        'https://api.snapihealth.com/mail/subscription',
        {
          method: 'post',
          body: JSON.stringify({ email, ageInMonths, tags: ['Sample Report'] }),
        }
      );

      const responseKlavio = await fetch(
        'https://www.snapihealth.com/api/subscription',
        {
          method: 'post',
          body: JSON.stringify({ email, ageInMonths, listId: 'UH5dbq' }),
        }
      );
      if (responseKlavio.status === 200) {
        console.log('connect to klaviyo done');
      }

      if (response.status === 200) {
        onClose(e);
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
        }, 5000);
      }
      if (response.status >= 500) {
        onClose(e);
        setShowNotification(true);
        setShowErrorNotification(true);
        setTimeout(() => {
          setShowNotification(false);
        }, 5000);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
    reset();
    setShowSpinner(false);
  }
  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            variants={Overlay}
            transition={{ duration: 0.55 }}
            initial="hidden"
            animate="visible"
            exit="hidden"
            onClick={onClick}
            className="bg-body fixed top-0 bottom-0 left-0 right-0  flex justify-center w-full z-[60]"
          />
        )}
      </AnimatePresence>

      {isOpen && (
        <motion.div
          variants={ModalAnimation}
          initial="closed"
          animate={isOpen ? 'opened' : 'closed'}
          transition={{ duration: 0.35 }}
          className={
            isOpen
              ? 'fixed mx-3 inset-y-0 my-auto inset-x-0 max-h-max bg-blue flex flex-col min-w-[290px] overflow-hidden max-w-[616px] rounded-2xl pt-6 pb-10 sm:mx-auto lg:pb-14 z-[70] xl:max-w-[800px]'
              : 'hidden'
          }
        >
          <div onClick={onClose} className="absolute top-[18px] right-[18px]">
            <CloseIcon
              width={10}
              height={10}
              className="cursor-pointer text-blue-dark"
            />
          </div>
          <h2 className="text-blue-dark text-center mb-3 mt-5">
            Get a sample report
          </h2>
          <p className="text-blue-dark px-3 text-center mb-10 lg:px-[78px] xl:px-[198px] ">
            Your baby&apos;s health shouldn&apos;t be a blackbox. Our vision is
            to empower every busy parent with accessible information to help
            them raise a healthier and stronger baby. Enter your email below and
            we&apos;ll send you our sample report:
          </p>

          <form
            className="flex flex-col mx-auto gap-2"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              {...register('email')}
              className="w-[260px] md:w-[280px]"
              type="email"
              required
              placeholder="email"
            />
            <Input
              {...register('ageInMonths')}
              className="w-[260px] md:w-[280px]"
              type="number"
              required
              placeholder="baby’s age (months)"
            />
            <div className="mx-auto mt-4">
              <button
                type="submit"
                className="w-[260px] h-[60px] md:w-[280px] rounded-[10px] uppercase disabled:bg-gray-soft disabled:shadow-none disabled:border-none text-body bg-green shadow-[1px_2px_0_0_#7DA46C] hover:bg-green-hover hover:shadow-none focus:bg-green-focus focus:shadow-none"
              >
                <h5>Send{showSpinner && <Spinner />}</h5>
              </button>
            </div>
          </form>

          <WhiteCircle
            width={200}
            height={200}
            className="absolute invisible lg:visible top-[130px] left-[-60px]"
          />
          <GreenCircle
            width={40}
            height={40}
            className="absolute invisible lg:visible top-[220px] right-[50px] xl:right-[110px]"
          />
          <PinkCircle
            width={184}
            height={178}
            className="absolute invisible lg:visible bottom-0 right-[-60px] xl:right-0"
          />
        </motion.div>
      )}
      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {showNotification && (
          <SubscriptionNotification
            setShowNotification={setShowNotification}
            variants={showErrorNotification}
            showNotification={showNotification}
          />
        )}
      </AnimatePresence>
    </>
  );
}
