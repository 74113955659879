import Image from 'next/image';
import OrangeHalfImg from './images/orange-half.png';
import FoodAllergiesImg from './images/food-allergies.png';
import ConstipationImg from './images/constipation.png';
import AsthmaImg from './images/asthma.png';
import ColicImg from './images/colic.png';
import LooseStoolImg from './images/loose-stool.png';
import EczemaImg from './images/eczema.png';

export function WhySection() {
  return (
    <section id="why" className="relative pt-[50px] pb-[100px] text-body">
      <div className="absolute invisible md:visible top-[-80px] right-0 w-[78px] h-[186px] xl:w-[98px]">
        <Image src={OrangeHalfImg} alt="" />
      </div>
      <div className="container flex flex-col max-w-[480px] sm:max-w-[768px] md:max-w-[860px] xl:max-w-[1000px]">
        <div className="relative flex flex-row h-[126px] sm:h-[176px] md:[184px] md:mt-14 sm:gap-10 md:gap-20 lg:gap-[200px] justify-center">
          <div className="absolute left-0 top-[20px] md:top-0 w-[94px] lg:top-[40px] lg:left-[-40px] sm:w-[148px] md:w-[174px] xl:w-[200px] h-[96px] sm:h-[152px] md:h-[178px] xl:h-[180px]">
            <Image src={FoodAllergiesImg} alt="" />
          </div>
          <div className="absolute top-[-70px] sml:top-[-50px] md:top-[-130px] xl:top-[-100px] w-[112px] sm:w-[172px] md:w-[200px]  xl:w-[210px] h-[168px] sm:h-[270px] md:h-[316px] xl:h-[336px]">
            <Image src={ConstipationImg} alt="" />
          </div>
          <div className="absolute top-[20px] right-0 md:top-0 lg:top-[40px] w-[91px] sm:w-[148px] md:w-[158px] xl:w-[167px] h-[93px] sm:h-[152px] md:h-[162px] xl:h-[171px]">
            <Image src={AsthmaImg} alt="" />
          </div>
        </div>
        <div className="flex flex-col items-center">
          <h2 className="text-center min-w-[272px] sm:w-[480px] md:w-[512px] xl:w-[514px] lg:-mt-8">
            9 out of 10 infants may be missing crucial gut bacteria
          </h2>
          <p className="text-center  min-w-[288px] sm:w-[404px] md:w-[452px] mt-6">
            Tracking the gut microbiome during the first three years can help
            prevent the development of common health conditions and get to the
            root cause of existing concerns. With access to microbiome testing,
            we have the power to create healthy futures for our children.
          </p>
        </div>
        <div className="relative flex flex-row sm:gap-10 md:gap-[72px] lg:gap-[200px] justify-center h-[126px] sm:h-[226px] md:h-[250px]">
          <div className="absolute left-0 bottom-[20px] sm:bottom-[70px] w-[94px] sm:w-[148px] md:w-[160px] lg:bottom-[90px] xl:w-[176px] h-[98px] sm:h-[154px] md:h-[166px] xl:h-[182px]">
            <Image src={ColicImg} alt="" />
          </div>

          <div className="absolute bottom-[-30px] sm:bottom-[10px] w-[94px] sm:w-[148px] md:w-[174px] xl:w-[180px] h-[96px] sm:h-[152px] md:h-[178px] xl:h-[204px]">
            <Image src={LooseStoolImg} alt="" />
          </div>

          <div className="absolute bottom-[8px] right-[-20px] w-[100px] sm:bottom-[70px] sm:w-[158px] lg:bottom-[90px] xl:w-[190px] h-[112px] sm:h-[165px] xl:h-[212px]">
            <Image src={EczemaImg} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}
