import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { motion, Variants } from 'framer-motion';
import { faSliders, faTimes } from '@fortawesome/pro-light-svg-icons';
import { useToggle } from 'react-use';
import { faCircleArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { useState } from 'react';
import Link from 'next/link';

export interface FaqLink {
  icon: IconDefinition;
  category: string;
  href: string;
}

export interface FaqNavigationProps {
  links: FaqLink[];
}

export const SideOverOverlay: Variants = {
  visible: { opacity: 1, display: 'flex', zIndex: 100 },
  hidden: { opacity: 0, transitionEnd: { zIndex: -20, display: 'none' } },
};

function Links({ links }: FaqNavigationProps) {
  const [selected, setSelected] = useState(links[0].href);

  return (
    <div className="flex flex-col flex-1">
      {links.map(({ icon, category: name, href }) => (
        <Link key={href} href={href} passHref>
          <a
            onClick={() => setSelected(href)}
            className={clsx(
              selected === href ? 'text-blue font-semibold' : 'text-dark',
              'flex my-2'
            )}
          >
            <FontAwesomeIcon
              icon={icon}
              className="w-4 h-4 mr-3 flex justify-center self-center"
            />
            <span className="flex flex-1 justify-start items-center text-[13px]">
              {name}
            </span>
          </a>
        </Link>
      ))}
    </div>
  );
}

export function FaqNavigation(props: FaqNavigationProps) {
  const [on, toggle] = useToggle(false);

  return (
    <>
      <div className="sticky top-[120px] self-start p-10 hidden lg:block divide-y">
        <Link href="/" passHref>
          <a className="flex pb-2">
            <FontAwesomeIcon
              icon={faCircleArrowLeft}
              className="w-4 h-4 mr-4 flex justify-center self-center text-blue"
            />
            <span className="flex flex-1 justify-start items-center text-[13px]">
              Go back to main page
            </span>
          </a>
        </Link>
        <Links {...props} />
      </div>
      <span className="flex flex-1 lg:hidden mb-3" onClick={toggle}>
        <FontAwesomeIcon
          icon={faSliders}
          {...props}
          className="flex self-center h-4 w-4"
        />
        <h5 className="flex items-center justify-center uppercase text-sm font-semibold ml-3">
          Filters
        </h5>
      </span>
      <motion.div
        variants={SideOverOverlay}
        transition={{ duration: 0.2 }}
        initial="hidden"
        animate={on ? 'visible' : 'hidden'}
        className="fixed top-0 bottom-0 left-0 right-0 z-40 justify-center w-full"
        onClick={toggle}
      >
        <div className="absolute bg-dark opacity-50 top-0 left-0 right-0 bottom-0" />
        <div className="relative z-10" role="dialog" aria-modal="true">
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <div className="relative bg-white rounded-lg transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                <span className="absolute right-4 top-4">
                  <FontAwesomeIcon icon={faTimes} {...props} />
                </span>
                <div className="px-5 py-3 min-w-[280px]">
                  <Links {...props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}
