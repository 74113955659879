import {
  faBarcodeRead,
  faCartShopping,
  faDna,
  faEnvelope,
  faVial,
  faRocketLaunch,
  faList,
  faLockKeyhole,
} from '@fortawesome/pro-light-svg-icons';
import { Faq } from '@snapi/types';
import { useMemo, useRef, useState } from 'react';
import { FaqCover } from './faq-cover';
import { FaqLink, FaqNavigation } from './faq-navigation';
import { FaqPanel } from './faq-panel';


const Links: FaqLink[] = [
  {
    icon: faRocketLaunch,
    category: 'About Snapi Health',
    href: '#about-snapi-health',
  },
  {
    icon: faDna,
    category: 'Learn',
    href: '#learn',
  },
  {
    icon: faEnvelope,
    category: 'Product information',
    href: '#product-information',
  },
  {
    icon: faCartShopping,
    category: 'Ordering a kit',
    href: '#ordering-a-kit',
  },
  {
    icon: faBarcodeRead,
    category: 'Kit activation',
    href: '#kit-activation',
  },
  {
    icon: faVial,
    category: 'Sample collection',
    href: '#sample-collection',
  },
  {
    icon: faList,
    category: 'Your results',
    href: '#your-results',
  },
  {
    icon: faLockKeyhole,
    category: 'Privacy',
    href: '#privacy',
  },
];

interface FaqPageProps {
  faq: Faq[];
}

export const FaqPage = ({ faq }: FaqPageProps) => {
  const [selected, setSelected] = useState<string | null>(null);

  const ref = useRef<HTMLDivElement>(null);

  const faqByCategory = useMemo(
    () =>
      Object.entries(
        (faq || []).reduce((agg: { [key in string]: Faq[] }, faq: Faq) => {
          if (agg[faq.category]) {
            agg[faq.category].push(faq);
          } else {
            agg[faq.category] = [faq];
          }
          return agg;
        }, {})
      )
        .map(([category, questions]) => ({
          category,
          slug: category.toLowerCase().replace(/\s/g, '-'),
          questions,
        }))
        .sort(
          (a, b) =>
            Links.findIndex(({ category: name }) => name === a.category) -
            Links.findIndex(({ category: name }) => name === b.category)
        ),
    [faq]
  );

  const handleSelect = (id: string) => {
    setSelected(id === selected ? null : id);
  };

  return (
    <>
      <FaqCover />
      <div
        id="faq"
        className="flex flex-col lg:flex-row lg:py-20 py-[60px] container relative"
      >
        <div className="flex flex-1 justify-center">
          <div className="flex flex-1 max-w-[630px]">
            <FaqNavigation links={Links} />
          </div>
        </div>
        <div
          className="flex flex-[2] flex-col max-w-[630px] self-center lg:self-start"
          ref={ref}
        >
          {faqByCategory.map((faqInCategory) => {
         
            return(
            <FaqPanel
              key={faqInCategory.slug}
              {...faqInCategory}
              selected={selected}
              onSelect={handleSelect}
            />)
          })}
  
        </div>
      </div>
    </>
  );
};
