import { Faq } from '@snapi/types';
import { FaqCard } from '@snapi/faq';
import { useState } from 'react';
import Image from 'next/image';
import GreenEggImg from './images/green-egg.png';
import YellowEggImg from './images/yellow-egg.png';
import BlueEggImg from './images/blue-egg.png';
import PurpleEggImg from './images/purple-egg.png';
import { Button } from '@snapi/luxury';
import Link from 'next/link';

interface FaqSectionProps {
  faq: Faq[];
}

export function FaqSection({ faq }: FaqSectionProps) {
  const [selected, setSelected] = useState<string | null>(null);

  const handleSelect = (id: string) => () => {
    if (id === selected) {
      setSelected(null);
    } else {
      setSelected(id);
    }
  };

  return (
    <section id="faq" className="bg-pink relative">
      <div className="container flex flex-col items-center text-center">
        <h5 className="z-10 text-pink-dark">FAQ</h5>
        <h2 className="mt-3 z-10 text-pink-dark">
          Interested in learning more?
        </h2>
        <div className="flex flex-col w-full md:max-w-[730px] justify-center items-center mt-10">
          {faq.map((faq) => (
            <FaqCard
              key={faq.id}
              {...faq}
              onSelect={handleSelect(faq.id)}
              selected={selected === faq.id}
            />
          ))}
            <Link href="/faq">
              <button className='mt-10 w-full sml:w-[376px] sm:w-[424px] md:w-[280px] h-[60px] bg-green rounded-full uppercase text-body font-semibold text-sm flex items-center justify-center'>
                Show me more!
              </button>
            </Link>

        </div>
      </div>
    </section>
  );
}
