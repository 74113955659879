import Image from 'next/image';
import BlueIcon from './images/blue-icon.png';
import PurpleIcon from './images/purple-icon.png';
import YellowIcon from './images/yellow-icon.png';
import BrownIcon from './images/brown-icon.png';
import CursorDownImg from './images/cursor-down.png';
import CursorUpImg from './images/cursor-up.png';

function IconOne() {
  return (
    <div className="text-white relative z-20 text-xl ml-3 font-semibold">
      1.
      <span className="absolute -z-10 top-[-10px] left-[-18px] w-12">
        <Image src={BlueIcon} alt="" />
      </span>
    </div>
  );
}

function IconTwo() {
  return (
    <p className="text-white relative z-20 text-xl ml-3 font-semibold">
      2.
      <span className="absolute -z-10 top-[-10px] left-[-16px] w-12">
        <Image src={PurpleIcon} alt="" />
      </span>
    </p>
  );
}

function IconThree() {
  return (
    <p className="text-white relative z-20 text-xl ml-3 font-semibold">
      3.
      <span className="absolute -z-10 top-[-10px] left-[-18px] w-12">
        <Image src={YellowIcon} alt="" />
      </span>
    </p>
  );
}

function IconFour() {
  return (
    <p className="text-white relative z-20 text-xl ml-3 font-semibold">
      4.
      <span className="absolute -z-10 top-[-10px] left-[-16px] w-12">
        <Image src={BrownIcon} alt="" />
      </span>
    </p>
  );
}

function CursorDown() {
  return (
    <div className="w-[98px] sm:w-[138px]">
      <Image src={CursorDownImg} alt="" />
    </div>
  );
}

function CursorUp() {
  return (
    <div className="w-[98px] sm:w-[138px]">
      <Image src={CursorUpImg} alt="" />
    </div>
  );
}

interface HowItWorkSectionCardProps {
  title: string | React.ReactNode;
  children: React.ReactNode;
  icon: JSX.Element;
  iconCursor?: JSX.Element;
  className: string;
  classNameCursor?: string;
  iconCursorUp?: JSX.Element;
}

function HowItWorkSectionCard({
  title,
  children,
  icon,
  iconCursor,
  className,
  iconCursorUp,
  classNameCursor,
}: HowItWorkSectionCardProps) {
  return (
    <div className={className}>
      <div className="w-[290px] sm:w-[300px] md:w-[324px] lg:w-[210px] xl:w-[280px]">
        {icon}
        <h3 className="mt-5 mb-3">{title}</h3>
        <p>{children}</p>
        <div className={classNameCursor}>{iconCursor}</div>
      </div>
    </div>
  );
}

export function HowItWorkSection() {
  return (
    <section id="how" className="bg-green text-green-dark">
      <div className="container relative">
        <h5 className="mb-3 uppercase">how it works</h5>
        <h2 className="mb-8">Four simple steps</h2>
        <div className="sm:flex flex-col lg:flex-row md:max-w-[768px] md:m-auto lg:max-w-full lg:gap-8 lg:h-[440px] xl:h-full xl:gap-7">
          <HowItWorkSectionCard
            title="Purchase test"
            icon={<IconOne />}
            iconCursor={<CursorDown />}
            className="relative mb-10"
            classNameCursor="absolute bottom-[-70px] right-[50px] rotate-0 sm:bottom-0 sm:right-[120px] md:right-[200px] lg:rotate-[300deg] lg:right-[-240px] lg:bottom-[230px] xl:bottom-[180px]"
          >
            Start your journey to better health by purchasing the test 
            on our website. Once purchased, you’ll receive instructions 
            on how to log in to your personal account, test kit instructions, 
            and a short questionnaire to help us personalize the recommendations 
            or your baby.
          </HowItWorkSectionCard>

          <HowItWorkSectionCard
            icon={<IconTwo />}
            iconCursor={<CursorUp />}
            classNameCursor="absolute bottom-[-70px] right-[80px] rotate-[130deg] sm:rotate-[120deg] sm:right-[360px] sm:bottom-[-20px] lg:rotate-0 lg:right-0 lg:left-[-200px] lg:bottom-[-80px] xl:bottom-[-60px]"
            title="Swab and send"
            className="relative mb-6 lg:mb-[80px] xl:mb-6 sm:flex flex-col items-end sm:mb-0.3 lg:flex-row"
          >
            Following a few simple steps in our instructions, collect your
            baby’s stool sample and send it to our lab in your prepaid
            envelope.
          </HowItWorkSectionCard>

          <HowItWorkSectionCard
            icon={<IconThree />}
            title="Recieve quick results"
            className="relative mb-6"
            iconCursor={<CursorDown />}
            classNameCursor="absolute right-0 top-[170px] rotate-[80deg] sm:invisible lg:visible lg:top-[30px] lg:rotate-[120px] lg:right-[-180px] xl:right-[-160px] xl:top-[-30px]"
          >
            Our lab will analyze your baby’s sample within 2-4 weeks. 
            We will notify you when your results are ready and accessible 
            through your personal account.
          </HowItWorkSectionCard>

          <HowItWorkSectionCard
            title="Discover, Improve, Repeat"
            icon={<IconFour />}
            className="sm:flex flex-col items-end  lg:flex-row"
            iconCursor={<CursorUp />}
            classNameCursor="absolute invisible sm:visible bottom-[60px] rotate-[40deg] right-[440px] lg:invisible"
          >
            Educate yourself on your baby’s gut microbiome and follow our
            clinical dietitians’ personalized recommendations. Testing every 3
            months has been shown to improve overall gut health.
          </HowItWorkSectionCard>
        </div>
      </div>
    </section>
  );
}
